<template>
  <div class="ibox companies">
    <div class="ibox-title">
      <h2>{{ $t('admin_companies.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listCompaniesLoading ? ' sk-loading' : '')">

      <div v-if="listCompaniesLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="listCompaniesProvider"
          :fields="listCompaniesFields"
          ref="listCompanies">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listCompaniesSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.web" :href="row.item.web" target="_blank">{{row.item.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateCompanyModal(row.item)">{{$t('company.info.update')}}</a>
            /
          </span>
          <span v-if="isRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveCompany(row.item)">{{$t('company.info.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showCompanyPage(row.item)">{{$t('company.info.details')}}</a>
        </template>
      </b-table>

      <button v-if="isCreateAllowed" class="btn btn-primary" @click="showCreateCompanyModal()">{{$t('company.info.create-button')}}</button>

      <b-modal size="lg" ref="updateCompanyModal" :title="'Update company ('+ companyToUpdate.name +')'" hide-footer lazy>
        <UpdateCompany :company="companyToUpdate" v-on:company-updated="onCompanyUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createCompanyModal" title="Create new company" hide-footer lazy>
        <CreateCompany v-on:company-created="onCompanyCreated" />
      </b-modal>

      <b-modal ref="removeCompanyModal" 
          hide-header
          @ok="removeCompany">
        {{$t('company.info.delete-confirmation', {companyName: companyToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import UpdateCompany from './Update.vue';
import CreateCompany from './Create.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    UpdateCompany,
    CreateCompany
  }
})
export default class ListCompanies extends Vue {

  isCreateAllowed = false;
  isUpdateAllowed = false;
  isRemoveAllowed = false;
  isMultiSelectAllowed = false;
  showNumberOfRows = false;

  numberOfRows:number = 0;

  companyToRemove:any = {};
  companyToUpdate:any = {};

  listCompaniesLoading:boolean = false;
  listCompaniesSelected = [];

  listCompaniesFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "name",
        label: ""
      },
      {
        key: "web",
        label: ""
      },
      {
        key: "options"
      }
    ];

  mounted() {
    this.listCompaniesFields[1].label = this.$t('company.info.name') as string;
    this.listCompaniesFields[2].label = this.$t('company.info.web') as string;
    this.listCompaniesFields[3].label = this.$t('company.headers.options') as string;
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  onCompanyRemoved() {
    // @ts-ignore
    this.$refs.listCompanies.refresh()
  }

  onCompanyCreated() {
    // @ts-ignore
    this.$refs.createCompanyModal.hide()
    // @ts-ignore
    this.$refs.listCompanies.refresh()
  }

  onCompanyUpdated(company:any) {
    // We update the company
    this.companyToUpdate = company;
    // @ts-ignore
    this.$refs.listCompanies.refresh()
  }
  
  confirmRemoveCompany(company:any) {
    this.companyToRemove = company;
    // @ts-ignore
    this.$refs.removeCompanyModal.show()
  }

  showUpdateCompanyModal(company:any) {
    this.companyToUpdate = company;
    // @ts-ignore
    this.$refs.updateCompanyModal.show()
  }

  showCreateCompanyModal() {
    // @ts-ignore
    this.$refs.createCompanyModal.show()
  }

  showCompanyPage(company:any) {
    this.$router.push("/"+this.currentLanguageCode+"/company/"+company._id).catch(err => {});
  }

  listCompaniesProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listCompaniesLoading = true;

    return api.getAPI('/api/admin/companies/list', options).then((response:any) => {
      // We check the additional fields
      if(response.fields) {
        this.listCompaniesFields.splice(2, 0, ...(response.fields.map((field:string) => {
          return {
            key: field,
            label: this.$t('company.headers.' + field) as string
          }
        })));
      }
      if(response.options) {
        this.isCreateAllowed = response.options.isCreateAllowed;
        this.isUpdateAllowed = response.options.isUpdateAllowed;
        this.isRemoveAllowed = response.options.isRemoveAllowed;
        this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
        this.showNumberOfRows = response.options.showNumberOfRows;
      }

      this.listCompaniesLoading = false;

      if(response.companies) {  
        if(this.showNumberOfRows) {
          this.numberOfRows = response.companies.length;
        }
        return response.companies;
      }
      else {
        return [];
      }
    })
  }

  removeCompany() {

    var input = {
      "companyID" : this.companyToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listCompaniesLoading = true;
    
    api.postAPI('/api/admin/companies/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of companies
        this.onCompanyRemoved();
      }
      // We reset the user to remove
      this.companyToRemove = {};

      this.listCompaniesLoading = false;
    });
  }
}
</script>