<template>
  <section>
    <div v-for="(value, key) in form" :key="key" class="form-group row">
        <label :for="'socialMedia_'+key" class="col-lg-3 col-form-label">{{ $t('company.socialMedias.'+key) }}</label>
        <div class="col-lg-9">
          <div class="input-group">
            <input :id="'socialMedia_'+key" type="text" :placeholder="$t('company.socialMedias.'+key+'_placeholder')" class="form-control" v-model="form[key]" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary remove" @click="removeItem($event, key)">{{ $t('company.socialMedias.form.remove-button') }}</button>
            </span>
          </div>
        </div>
    </div>
    <div v-if="itemsToAdd.length > 0" class="form-group row">
        <div class="col-lg-3">
          <v-select :options="itemsToAdd" :getOptionLabel="getItemLabel" v-model="addItemForm.key" :placeholder="$t('company.socialMedias.form.key_placeholder')" @keypress.enter.native.prevent="" />
        </div>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="text" :placeholder="$t('company.socialMedias.form.value_placeholder')" class="form-control" v-model="addItemForm.value" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary add" :disabled="!isItemToAddEnabled" @click="addItem">{{ $t('company.socialMedias.form.add-button') }}</button>
            </span>
          </div>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

@Component({
  components: { }
})
export default class SocialMedias extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly socialMedias!: any | undefined

  form = this.socialMedias ? this.socialMedias : {};

  addItemForm = {
    key : '',
    value : ''
  }

  supportedItems:string[] = [
    "facebook",
    "instagram"
  ];

  created() {
    
  }

  get isItemToAddEnabled() {
    var itemsToAdd = this.itemsToAdd;
    if(itemsToAdd.indexOf(this.addItemForm.key) < 0) {
      return false;
    }
    if(this.addItemForm.value == "") {
      return false;
    }
    return true;
  }

  get itemsToAdd() {
    // We get the existing items
    var existingItems = Object.keys(this.form);
    // We remove the existing items from supported items.
    return this.supportedItems.filter(function(key) {
      return existingItems.indexOf(key) < 0;
    })
  }

  getItemLabel(key:string) {
    if(this.$i18n.te('company.socialMedias.'+key)) {
      return this.$i18n.t('company.socialMedias.'+key);
    }
    return key.toUpperCase();
  }

  addItem(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.set(this.form, this.addItemForm.key, this.addItemForm.value);

    this.addItemForm.key = '';
    this.addItemForm.value = '';
  }

  removeItem(evt:Event, key:string) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.delete(this.form, key);
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:socialMedias', val);
  }
  
}
</script>