<template>
  <section>
    <div v-for="(email, index) in form" :key="email.type" class="form-group row">
        <label :for="'updateEMail_'+email.type" class="col-lg-3 col-form-label">{{ listEMailTypesLabel(email.type) }}</label>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="email" :id="'updateEMail_'+email.type" class="form-control" v-model="form[index].email" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary removeEMail" @click="removeEMail($event, index)">{{ $t('emails.form.remove-button') }}</button>
            </span>
          </div>
        </div>
    </div>
    <div v-if="emailTypesToAdd.length > 0" class="form-group row">
        <div class="col-lg-3">
          <v-select :options="emailTypesToAdd" :getOptionLabel="listEMailTypesLabel" :id="'addEMailType'" :placeholder="$t('emails.form.type_placeholder')" @keypress.enter.native.prevent="" v-model="addForm.type"/>
        </div>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="email" id="addEMail" class="form-control" v-model="addForm.email" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary addEMail" :disabled="!isAddEMailEnabled" @click="addEMail">{{ $t('emails.form.add-button') }}</button>
            </span>
          </div>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

@Component({
  components: { }
})
export default class UpdateEMails extends Vue {

  @Prop({
    type: Array,
    required: false
  }) readonly emails!: any | undefined

  form:any[] = this.emails ? this.emails : [];

  addForm = {
    type : '',
    email : ''
  }

  listEMailTypes:any[] = [];

  created() {
    this.updateListEMailTypes();
  }

  mounted() {
  }

  updateListEMailTypes() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listEMailTypes', options).then((response:any) => {
      if(response.emailTypes) {  
        this.listEMailTypes = response.emailTypes;
      }
    });
  }

  get emailTypesToAdd() {
    // We get the existing phone types
    var existingTypes = this.form.map(function(email:any) {
      return email.type;
    });
    // We remove the existing phone types from supported types.
    return this.listEMailTypes.filter(function(type) {
      return existingTypes.indexOf(type) < 0;
    })
  }

  listEMailTypesLabel(typeCode:string) {
    if(this.$te('emails.types.'+typeCode)) {
        return this.$t('emails.types.'+typeCode);
    }
    return typeCode;
  }

  get isAddEMailEnabled() {
    return this.addForm.type != "" && this.addForm.email != "";
  }

  addEMail(evt:Event) {
    evt.preventDefault();

    var email = {
      "email" : this.addForm.email,
      "type" : this.addForm.type
    }

    this.form.push(email);
    
    this.addForm.type = '';
    this.addForm.email = '';
  }

  removeEMail(evt:Event, index:number) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.delete(this.form, index);
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListEMailTypes();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:emails', val);
  }
  
}
</script>