<template>
  <section>
    <div v-for="(value, key) in form" :key="key" class="form-group row">
        <label :for="'updateIdentifier_'+key" class="col-lg-3 col-form-label">{{ $t('company.identifiers.'+key) }}</label>
        <div class="col-lg-9">
          <div class="input-group">
            <input :id="'updateIdentifier_'+key" type="text" :placeholder="$t('company.identifiers.'+key+'_placeholder')" class="form-control" v-model="form[key]" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary removeIdentifier" @click="removeIdentifier($event, key)">{{ $t('company.identifiers.form.remove-button') }}</button>
            </span>
          </div>
        </div>
    </div>
    <div v-if="noIdentifiersReason != ''" class="form-group row">
        <div class="col-lg-12">
          {{ noIdentifiersReason }}
        </div>
    </div>
    <div v-else-if="identifiersToAdd.length > 0" class="form-group row">
        <div class="col-lg-3">
          <v-select :options="identifiersToAdd" :getOptionLabel="getIdentifierLabel" v-model="addIdentifierForm.key" :placeholder="$t('company.identifiers.form.key_placeholder')" @keypress.enter.native.prevent="" />
        </div>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="text" :placeholder="$t('company.identifiers.form.value_placeholder')" class="form-control" v-model="addIdentifierForm.value" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary addIdentifier" :disabled="!isIdentifierToAddEnabled" @click="addIdentifier">{{ $t('company.identifiers.form.add-button') }}</button>
            </span>
          </div>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

@Component({
  components: { }
})
export default class UpdateIdentifiers extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly identifiers!: any | undefined

  @Prop({
    type: String,
    required: true
  }) readonly companyID!: string


  form = this.identifiers ? this.identifiers : {};

  addIdentifierForm = {
    key : '',
    value : ''
  }

  supportedIdentifiers:string[] = [];
  noIdentifiersReason:string = '';

  created() {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    var input = {
      "companyID" : this.companyID
    }
    api.postAPI('/api/admin/companies/getListIdentifiers', input, options).then((response:any) => {
      if(response.identifiers) {  
        this.supportedIdentifiers = response.identifiers;
        this.noIdentifiersReason = '';
      }
      if(response.noIdentifiersReason) {
        this.noIdentifiersReason = response.noIdentifiersReason;
      }
    });
  }

  get isIdentifierToAddEnabled() {
    var identifiersToAdd = this.identifiersToAdd;
    if(identifiersToAdd.indexOf(this.addIdentifierForm.key) < 0) {
      return false;
    }
    if(this.addIdentifierForm.value == "") {
      return false;
    }
    return true;
  }

  get identifiersToAdd() {
    // We get the existing identifiers
    var existingIdentifiers = Object.keys(this.form);
    // We remove the existing identifiers from supported identifiers.
    return this.supportedIdentifiers.filter(function(identifier) {
      return existingIdentifiers.indexOf(identifier) < 0;
    })
  }

  getIdentifierLabel(identifier:string) {
    if(this.$i18n.te('company.identifiers.'+identifier)) {
      return this.$i18n.t('company.identifiers.'+identifier);
    }
    return identifier.toUpperCase();
  }

  addIdentifier(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.set(this.form, this.addIdentifierForm.key, this.addIdentifierForm.value);

    this.addIdentifierForm.key = '';
    this.addIdentifierForm.value = '';
  }

  removeIdentifier(evt:Event, key:string) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.delete(this.form, key);
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:identifiers', val);
  }
  
}
</script>