<template>
  
    <form role="form" @submit="onFormSubmit" name="update-company">
      <div class="row">
        <div class="col-lg-12">
          <h3>Logo</h3>
        </div>
      </div>

      <div class="row" v-if="companyLogo">
        <div class="col-lg-12 text-center m-xs">
          <img :src="getStaticURLFromPath(companyLogo.path)" height="50px" />
        </div>
      </div>

      <div class="form-group row">
        <label for="updateLogo" class="col-lg-3 col-form-label">{{ $t('company.info.logo') }}</label>
        <div class="col-lg-9">
          <b-form-file
            id="logo"
            v-model="form.logo"
            :placeholder="$t('company.info.logo_placeholder')"
            :drop-placeholder="$t('company.info.logo_drop-placeholder')"
          />
        </div>
      </div>

      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Information</h3>
        </div>
      </div>
      <div class="form-group row">
          <label for="updateName" class="col-lg-3 col-form-label">Name</label>
          <div class="col-lg-9">
            <input id="updateName" type="text" placeholder="Name" class="form-control" v-model="form.name">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateSource" class="col-lg-3 col-form-label">{{$t('company.info.source')}}</label>
          <div class="col-lg-9">
            <select id="updateSource" class="form-control" v-model="form.source">
              <option value="">{{$t('company.info.source_select')}}</option>
              <option v-for="(source, index) in companySources" :key="index" :value="source">{{source}}</option>
            </select>
          </div>
      </div>
      <div class="form-group row">
          <label for="updateSize" class="col-lg-3 col-form-label">Size</label>
          <div class="col-lg-9">
            <select id="updateSize" class="form-control" v-model="form.size">
              <option value="">{{$t('company.info.nbOfEmployees_select')}}</option>
              <option v-for="(nb, index) in nbOfEmployees" :key="index" :value="nb">{{$t('nbOfEmployees.'+nb)}}</option>
            </select>
          </div>
      </div>
      <div class="form-group row">
          <label for="updateRevenue" class="col-lg-3 col-form-label">Revenue</label>
          <div class="col-lg-9">
            <input id="updateRevenue" type="text" placeholder="Revenue" class="form-control" v-model="form.revenue">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateWeb" class="col-lg-3 col-form-label">Web</label>
          <div class="col-lg-9">
            <input id="updateWeb" type="text" placeholder="Web" class="form-control" v-model="form.web">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateVatNumber" class="col-lg-3 col-form-label">VAT Number</label>
          <div class="col-lg-9">
            <div class="input-group">
              <input id="updateVatNumber" type="text" placeholder="VAT Number" class="form-control" v-model="form.vatNumber">
              <span class="input-group-append">
                <button type="button" class="btn btn-primary validateVAT" @click="validateVAT">Validate</button>
              </span>
            </div>
          </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Identifiers</h3>
        </div>
      </div>
      <UpdateIdentifiers v-bind:identifiers.sync="form.identifiers" :companyID="company._id" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Social Medias</h3>
        </div>
      </div>
      <SocialMedias v-bind:socialMedias.sync="form.socialMedias" :companyID="company._id" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Phones</h3>
        </div>
      </div>
      <UpdatePhones v-bind:phones.sync="form.phones" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>EMails</h3>
        </div>
      </div>
      <UpdateEMails v-bind:emails.sync="form.emails" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Address</h3>
        </div>
      </div>
      <Address 
        v-bind:value.sync="form.address"
        :options="addressOptions"
        :labelAsColumn="true"
      />
      <div class="hr-line-dashed"></div>
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">Update</button>
    </form>

</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';
import Address from '@fwk-client/components/panels/input/Address.vue';
import UpdatePhones from '../../contact/phones/UpdatePhones.vue'
import UpdateEMails from '../../UpdateEMails.vue'
import UpdateIdentifiers from './UpdateIdentifiers.vue'
import SocialMedias from './SocialMedias.vue'
import { positions, nbOfEmployees, companySources } from '../../../../types/common';

@Component({
  components: {
    Address,
    UpdateIdentifiers,
    SocialMedias,
    UpdatePhones,
    UpdateEMails
   }
})
export default class Update extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly company!: any | undefined

  companyLogo = this.company.logo;

  form = {
    name: this.company.name,
    size: this.company.size,
    revenue : this.company.revenue,
    web : this.company.web,
    identifiers : this.company.identifiers,
    socialMedias : this.company.socialMedias,
    phones : this.company.phones,
    emails : this.company.emails,
    address : this.company.address,
    vatNumber : this.company.vatNumber,
    source : this.company.source,
    logo : null
  };

  addressOptions = {
    showName: true,
    labelAsColumn: true,
    nbAddressLines: 2,
    showGeolocation: true
  }

  nbOfEmployees = nbOfEmployees;
  companySources = companySources;

  laddaSubmit:Ladda.LaddaButton|null = null;
  laddaValidateVAT:Ladda.LaddaButton|null = null;

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-company] button.update' );
    this.laddaSubmit = Ladda.create(submitButton!);

    var validateVATButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-company] button.validateVAT' );
    this.laddaValidateVAT = Ladda.create(validateVATButton!);
  }

  validateVAT(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "vatNumber" : this.form.vatNumber,
      "countryCode" : (this.form.address && this.form.address.countryCode) ? this.form.address.countryCode : undefined
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaValidateVAT!.start();
    
    api.postAPI('/api/utils/companies/validateVAT', input, options).then((response:any) => {
      if(response.validated) {  
        
      }
      else {

      }

      this.laddaValidateVAT!.stop();
    });
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "companyID" : this.company._id,
      "name" : this.form.name,
      "size" : this.form.size,
      "revenue" : this.form.revenue,
      "web" : this.form.web,
      "identifiers" : this.form.identifiers,
      "socialMedias" : this.form.socialMedias,
      "phones" : this.form.phones,
      "emails" : this.form.emails,
      "address" : this.form.address,
      "vatNumber" : this.form.vatNumber,
      "source" : this.form.source
    }

    var formData = new FormData();
    if(this.form.logo != null) {
      formData.append("logo", this.form.logo!, (this.form.logo! as File).name);  
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPIFormData('/api/admin/companies/update', formData, options, input).then((response:any) => {
      if(response.updated) {  
        var company = response.company;
        // We emit event as user is updated
        this.$emit('company-updated', company);

        // We update the form based on values returned by backend (in case not updated)
        this.form.name = company.name;
        this.companyLogo = company.logo;
      }

      this.laddaSubmit!.stop();
    });
    
  }
  
}
</script>