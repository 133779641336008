<template>
  
    <form role="form" @submit="onFormSubmit" name="create-company">
        <div class="form-group">
            <label for="createName" class="sr-only">Name</label>
            <input id="createName" type="text" placeholder="Name" class="form-control" v-model="form.name" required />
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Create extends Vue {

  form = {
    name: ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-company] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "name" : this.form.name
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/companies/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('company-created', response.company);

        // We reset the field
        this.form.name = "";
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>