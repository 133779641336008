<template>
  <div class="ibox requests">
    <div class="ibox-title">
      <h2>{{ $t('admin_eurofiscalis.list_workers.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listRequestsLoading ? ' sk-loading' : '')">

      <div v-if="listRequestsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showTotalAmount">Total Amount: {{totalAmount}}</p>
      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="listRequestsProvider"
          :fields="listRequestsFields"
          ref="listRequests">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listRequestsSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveRequest(row.item)">{{$t('admin_eurofiscalis.list_workers.options.delete')}}</a>
          </span>
          <span v-if="isRemoveAllowed && isValidationAllowed">
            /
          </span>
          <span v-if="isValidationAllowed">
            <a href="javascript:void(0)" @click="confirmValidateRequest(row.item)">{{$t('admin_eurofiscalis.list_workers.options.validate')}}</a>
          </span>
        </template>
      </b-table>

      <b-modal ref="removeRequestModal" 
          hide-header
          @ok="removeRequest">
        {{$t('admin_eurofiscalis.list_workers.delete-confirmation', [requestToRemove.company.name])}}
      </b-modal>

      <b-modal ref="validateRequestModal" 
          hide-header
          @ok="validateRequest">
        {{$t('admin_eurofiscalis.list_workers.validate-confirmation', [requestToValidate.company.name])}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    
  }
})
export default class ListRequests extends Vue {

  isRemoveAllowed = false;
  isValidationAllowed = false;
  isMultiSelectAllowed = false;
  showTotalAmount = false;
  showNumberOfRows = false;

  emptyRequest = {
    company: {
      name: ""
    }
  }

  totalAmount:number = 0;
  numberOfRows:number = 0;

  requestToRemove:any = {...this.emptyRequest};
  requestToValidate:any = {...this.emptyRequest};

  listRequestsLoading:boolean = false;
  listRequestsSelected = [];

  listRequestsFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "company.name"
      },
      {
        key: "user.login"
      },
      {
        key: "user.profile.phones[0].number",
        label: "Phone"
      },
      {
        key: "representativeCountryCodes"
      },
      {
        key: "allowedNumberOfWorkers"
      },
      {
        key: "transaction.amount"
      },
      {
        key: "options"
      }
    ];

  mounted() {
    /*
    this.listRequestsFields[1].label = this.$t('company.info.name') as string;
    this.listRequestsFields[2].label = this.$t('company.info.web') as string;
    this.listRequestsFields[3].label = this.$t('company.headers.options') as string;
    */
  }

  onRequestRemoved() {
    // @ts-ignore
    this.$refs.listRequests.refresh()
  }
  
  confirmRemoveRequest(request:any) {
    this.requestToRemove = request;
    // @ts-ignore
    this.$refs.removeRequestModal.show()
  }

  onRequestValidated() {
    this.$emit('worker-request-validated');
    // @ts-ignore
    this.$refs.listRequests.refresh();
  }

  confirmValidateRequest(request:any) {
    this.requestToValidate = request;
    // @ts-ignore
    this.$refs.validateRequestModal.show()
  }

  listRequestsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/workers/list-requests', options).then((response:any) => {
      // We check the additional fields
      if(response.options) {
        this.isRemoveAllowed = response.options.isRemoveAllowed;
        this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
        this.isValidationAllowed = response.options.isValidationAllowed;
        this.showTotalAmount = response.options.showTotalAmount;
        this.showNumberOfRows = response.options.showNumberOfRows;
      }
      if(response.requests) {  
        if(this.showTotalAmount) {
          this.totalAmount = response.requests.reduce((acc:number, val:any) => {
            return acc + val.transaction.amount;
          },0)
        }
        if(this.showNumberOfRows) {
          this.numberOfRows = response.requests.length;
        }
        return response.requests;
      }
      else {
        return [];
      }
    })
  }

  removeRequest() {

    var input = {
      "requestID" : this.requestToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listRequestsLoading = true;
    
    api.postAPI('/api/admin/workers/request/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of requests
        this.onRequestRemoved();
      }
      // We reset the request to remove
      this.requestToRemove = {...this.emptyRequest};

      this.listRequestsLoading = false;
    });
  }

  validateRequest() {

    var input = {
      "requestID" : this.requestToValidate._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listRequestsLoading = true;
    
    api.postAPI('/api/admin/workers/request/validate', input, options).then((response:any) => {
      if(response.validated) {
        // We update the list of requests
        this.onRequestValidated();
      }
      // We reset the request to validate
      this.requestToValidate = {...this.emptyRequest};

      this.listRequestsLoading = false;
    });
  }
}
</script>