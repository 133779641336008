<template>
  <section>
    <div v-for="(phone, index) in form" :key="phone.type" class="form-group row">
        <label :for="'updatePhoneNumber_'+phone.type" class="col-lg-3 col-form-label">{{ listPhoneTypesLabel(phone.type) }}</label>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="tel" readonly :id="'updatePhoneNumber_'+phone.type" class="form-control" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary removePhone" @click="removePhone($event, index, phone.type)">{{ $t('phones.form.remove-button') }}</button>
            </span>
          </div>
        </div>
    </div>
    <div v-if="phoneTypesToAdd.length > 0" class="form-group row">
        <div class="col-lg-3">
          <v-select :options="phoneTypesToAdd" :getOptionLabel="listPhoneTypesLabel" :id="'addPhoneType'" :placeholder="$t('phones.form.type_placeholder')" @keypress.enter.native.prevent="" v-model="addForm.type"/>
        </div>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="tel" id="addPhoneNumber" class="form-control" v-model="addForm.number" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary addPhone" :disabled="!isAddPhoneEnabled" @click="addPhone">{{ $t('phones.form.add-button') }}</button>
            </span>
          </div>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { setTimeout } from 'timers';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

@Component({
  components: { }
})
export default class UpdatePhones extends Vue {

  @Prop({
    type: Array,
    required: false
  }) readonly phones!: any | undefined

  form:any[] = [];

  addForm = {
    type : '',
    number : ''
  }

  phonesIntlTel:any = {};
  addPhoneIntlTel:intlTelInput.Plugin|null = null;

  listPhoneTypes:any[] = [];

  created() {
    this.updateListPhoneTypes();
  }

  mounted() {
    // We generate existing phone fields
    for(var phone of this.phones) {
      this.addPhoneInForm(phone);
    }
  }

  reloadIntlTel() {
    for(var phone of this.form) {
      this.phonesIntlTel[phone.type].destroy();
      var intlTel = intlTelInput(document.querySelector("#updatePhoneNumber_"+phone.type) as Element, {
        preferredCountries : ['fr'],
        allowDropdown : false,
        // @ts-ignore
        customContainer : "flag-left"
      });
      intlTel.setNumber(phone.number);
      this.phonesIntlTel[phone.type] = intlTel;
    }
  }

  updateListPhoneTypes() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listPhoneTypes', options).then((response:any) => {
      if(response.phoneTypes) {  
        this.listPhoneTypes = response.phoneTypes;
      }
    });
  }

  get phoneTypesToAdd() {
    // We get the existing phone types
    var existingTypes = this.form.map(function(phone:any) {
      return phone.type;
    });
    // We remove the existing phone types from supported types.
    return this.listPhoneTypes.filter(function(type) {
      return existingTypes.indexOf(type) < 0;
    })
  }

  listPhoneTypesLabel(typeCode:string) {
    if(this.$te('phones.types.'+typeCode)) {
        return this.$t('phones.types.'+typeCode);
    }
    return typeCode;
  }

  get isAddPhoneEnabled() {
    return this.addForm.type != "" && this.addForm.number != "" && 
        this.addPhoneIntlTel != null && this.addPhoneIntlTel.isValidNumber();
  }

  addPhone(evt:Event) {
    evt.preventDefault();

    var phone = {
      "number" : this.addPhoneIntlTel!.getNumber(),
      "type" : this.addForm.type
    }

    this.addPhoneInForm(phone);
    
    this.addForm.type = '';
    this.addForm.number = '';
  }

  addPhoneInForm(phone:any) {
    this.form.push(phone);

    var _self = this;
    Vue.nextTick(function () {
      var intlTel = intlTelInput(document.querySelector("#updatePhoneNumber_"+phone.type) as Element, {
        preferredCountries : ['fr'],
        allowDropdown : false,
        // @ts-ignore
        customContainer : "flag-left"
      });
      intlTel.setNumber(phone.number);
      _self.phonesIntlTel[phone.type] = intlTel;
    });
  }

  removePhone(evt:Event, index:number, type:string) {
    // We prevent submit of the page
    evt.preventDefault();

    // We remove the plugin
    this.phonesIntlTel[type].destroy();
    delete this.phonesIntlTel[type];

    Vue.delete(this.form, index);

    var _self = this;
    Vue.nextTick(function() {
      _self.reloadIntlTel();
    })
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListPhoneTypes();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:phones', val);
  }

  @Watch('phoneTypesToAdd')
  onPhoneTypesToAddChanged(val: any, oldVal: any) {
    if(val.length == 0 && this.addPhoneIntlTel != null) {
      this.addPhoneIntlTel.destroy();
    }
    if(oldVal && oldVal.length == 0 && val.length > 0) {
      var _self = this;
      Vue.nextTick(function() {
        var input = document.querySelector("#addPhoneNumber");
        // We generate the add phone field
        if(input) {
          _self.addPhoneIntlTel = intlTelInput(input as Element, {
            preferredCountries : ['fr']
          });
        }
      })
    }
  }
  
}
</script>